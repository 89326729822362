import React, { useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';

import { IdsButton, IdsButtonGroup, IdsLink, IdsModal, IdsText } from '@emergn-infinity/ids-react';

import { AppRoutes, PRAXIS_MARKETING_WEBSITE } from '@/constants/general';
import { PraxisLogo } from '../../../public/images';

import './logo-header.scss';

interface CtaContent {
  label?: string;
  buttonLabel: string;
  buttonIconRight?: string;
  buttonRoute?: string;
  buttonClickHandler?: () => void;
}

const LogoHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const page = usePathname();
  const router = useRouter();

  const ctas: Record<string, CtaContent> = {
    [AppRoutes.SignIn]: {
      label: 'Not a member?',
      buttonLabel: 'Register here',
      buttonRoute: `/app${AppRoutes.SignUp}`,
    },
    [AppRoutes.SignUp]: {
      label: 'Already a member?',
      buttonLabel: 'Sign in',
      buttonRoute: `/app${AppRoutes.SignIn}`,
    },
    [AppRoutes.ForgotPassword]: {
      buttonLabel: 'Back to sign in',
      buttonRoute: `/app${AppRoutes.SignIn}`,
    },
    [AppRoutes.PasswordSent]: {
      buttonLabel: 'Back to sign in',
      buttonRoute: `/app${AppRoutes.SignIn}`,
    },
    [AppRoutes.SignInTwoFactorAuthenticationVerification]: {
      buttonLabel: 'Cancel',
      buttonIconRight: 'ui-form-error',
      buttonClickHandler: () => setIsModalOpen(true),
    },
  };

  const cta = ctas[page] || {};

  const handleConfirmCancel = () => {
    setIsModalOpen(false);
    router.push(AppRoutes.SignIn);
  };

  return (
    <>
      <div className="LogoHeader">
        <IdsLink href={PRAXIS_MARKETING_WEBSITE} target="_blank" customClasses="LogoHeaderLogo">
          <PraxisLogo />
        </IdsLink>
        {cta.buttonLabel && (
          <div className="LogoHeaderCta">
            {cta.label && <IdsText size="sm">{cta.label}</IdsText>}
            <IdsButton
              variant="secondary"
              color="neutral"
              href={cta.buttonRoute}
              clickHandler={cta.buttonClickHandler}
              iconRight={cta.buttonIconRight}
              data-elp-testid={cta.buttonLabel}
            >
              <>{cta.buttonLabel}</>
            </IdsButton>
          </div>
        )}
      </div>

      {isModalOpen && (
        <IdsModal isOpen={true} closeHandler={() => setIsModalOpen(false)}>
          <div slot="header">
            <IdsText component="h2" weight="bold" size="sm">
              Cancel account verification?
            </IdsText>
          </div>
          <div slot="main">
            <IdsText isInline>
              If you cancel now, you will not be able to log in to your account until you complete the 2FA verification
              process.
            </IdsText>
            <IdsText>
              Two-Factor Authentication is required for your account’s security. Please ensure you have access to your
              authenticator app to proceed.
            </IdsText>
          </div>
          <div slot="footer">
            <IdsButtonGroup spaceBetween="lg" position="right">
              <IdsButton variant="tertiary" color="brand" clickHandler={handleConfirmCancel}>
                Confirm cancel
              </IdsButton>
              <IdsButton clickHandler={() => setIsModalOpen(false)}>Continue login</IdsButton>
            </IdsButtonGroup>
          </div>
        </IdsModal>
      )}
    </>
  );
};

export default LogoHeader;
